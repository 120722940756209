import React, {useContext, useEffect, useState} from "react";
import {BaseContext, formatEventString, getDescriptionForRule, IntegrationContext} from "../helpers/common";
import {serverDelete, serverFetch, serverPost} from "../helpers/server";
import InfiniteScroll from "react-infinite-scroll-component";
import Section from "./Section";
import MapleTable from "./MapleTable";
import MapleTableHeaderWithActions from "./MapleTableHeaderWithActions";
import SortableTableHeader from "./SortableTableHeader";
import moment from "moment";
import ContentContainer from "./ContentContainer";
import CreateOrUpdateWorkflowRuleModal from "./modals/CreateOrUpdateWorkflowRuleModal";
import DeleteButton from "./DeleteButton";
import Notification from './Notification';

const _ = require('lodash');

function WorkflowRulesComponent(props) {
    const { company, getApiUrl } = useContext(BaseContext);
    const { integration } = useContext(IntegrationContext);
    const [channels, setChannels] = useState([]);
    const [ruleAreas, setRuleAreas] = useState([]);
    const [ruleMap, setRuleMap] = useState({});
    const [messageMap, setMessageMap] = useState({});
    const [loading, setLoading] = useState(true);
    const [sort, setSort] = useState("createdAtDesc");
    const [fromKey, setFromKey] = useState(null);
    const [rules, setRules] = useState([]);
    const [meta, setMeta] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [showWorkflowRuleModal, setShowWorkflowRuleModal] = useState(false);
    const [currentRule, setCurrentRule] = useState(null);
    const [ruleValueOptions, setRuleValueOptions] = useState({});

    const fetchData = (skipCache=false) => {
        serverFetch(getApiUrl(`/integrations/slack/channels`), {skipCache: skipCache}).then(res => {
            if (res) {
                const results = res.channels || []
                setChannels(results)
            }
        })

        serverFetch(getApiUrl(`/workflows/events_mapping`, {skipCache: skipCache})).then(res => {
            if (res) {
                const ruleAreas = Object.keys(res)
                setRuleAreas(ruleAreas)
                setRuleMap(res)
            }
        })

        serverFetch(getApiUrl(`/workflows/slack_message_mapping`), {skipCache: skipCache}).then(res => {
            if (res) {
                setMessageMap(res)
            }
        })
    }

    const onSearch = (restart=true) => {
        const limit=50
        const params = {
            sort_key: sort || "createdAtDesc",
            pagination: {
                from_key: restart ? null: fromKey,
                limit: limit
            },
            query: {
                integration_id: integration.id
            },
            include_meta: restart
        }
        serverPost(getApiUrl(`/workflows/find_workflow_rules`), params).then((res) => {
            if (res) {
                const results = res.results || [];
                if (restart) {
                    setRules(results)
                } else {
                    setRules(_.concat(rules, results));
                }

                if (res.meta) {
                    setMeta(res.meta);
                }

                setLoading(false);
                setFromKey(res.pagination.from_key);
                setHasMore(results.length === limit);
            }
        })
    }

    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
        onSearch(true);
    }, [sort]);

    const getSlackChannelNameFromRule = (rule) => {
        if (!rule) return "";
        const action = _.find(rule.actions, (action) => action.integration_id === integration.id)
        return action.params.slack.channel
    }

    const onWorkflowRuleModalClose = (didUpdate) => {
        setShowWorkflowRuleModal(false);
        setCurrentRule(null);

        if (didUpdate) {
            onSearch(true)
        }
    }

    const onRowClick = (rule) => {
        setCurrentRule(rule);
        setShowWorkflowRuleModal(true);
    }

    useEffect(() => {
        const rules = _.uniqBy(_.flatten(_.map(ruleMap, "rules_options")), "key")
        const options = _.map(rules, (r) => {
            return {
                value: r.key,
                label: r.label
            }
        })
        setRuleValueOptions(options)
    }, [ruleMap])

    const getRuleValueOptions =() => {
        const rules = _.uniqBy(_.flatten(_.map(ruleMap, "rules_options")), "key")
        return _.map(rules, (r) => {
            return {
                value: r.key,
                label: r.label
            }
        })
    }

    const onDeleteWorkflowRule = (rule) => {
        serverDelete(getApiUrl(`/workflows/${rule.id}`)).then(res => {
            if (res) {
                Notification.Success("Workflow rule deleted");
                onSearch(true);
            }
        })
    }

    return (
        <>
        <ContentContainer className={"bg-white"}>
            <div className="m-3">
                <InfiniteScroll
                    next={() => onSearch(false)}
                    hasMore={hasMore}
                    scrollableTarget={"content-wrapper"}
                    dataLength={rules.length}
                >
                    <Section
                        title={"Notification Rules"}
                        subtitle={''}
                        loading={loading}
                        actions={[{
                            variant: "primary",
                            icon: "fa-plus",
                            label: "Create Rule",
                            onClick: () => setShowWorkflowRuleModal(true)
                        }]}
                    >
                    {
                        _.isEmpty(rules) &&
                        <span className="text-gray-500">No workflow rules setup.</span>
                    }
                    {
                        !_.isEmpty(rules) &&
                        <MapleTable>
                            <MapleTableHeaderWithActions
                                meta={meta}
                            />
                            <MapleTable.Content>
                                <thead>
                                <tr>
                                    <MapleTable.TH>Name</MapleTable.TH>
                                    <MapleTable.TH>Rule</MapleTable.TH>
                                    <MapleTable.TH>Trigger</MapleTable.TH>
                                    <MapleTable.TH>Channel</MapleTable.TH>
                                    <MapleTable.TH></MapleTable.TH>
                                </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200">
                                {
                                    _.map(rules, (row, i) =>
                                        <MapleTable.TR key={i} className="cursor-pointer hover:bg-gray-100"
                                                       onClick={() => onRowClick(row)}>
                                            <td>{row.name}</td>
                                            <td>{getDescriptionForRule(row.rule, ruleValueOptions)}</td>
                                            <td>{formatEventString(row.trigger_event)}</td>
                                            <td>{getSlackChannelNameFromRule(row)}</td>
                                            <td className="w-px whitespace-nowrap" onClick={(e) => e.stopPropagation()}>
                                                <DeleteButton size="md" onDelete={() => onDeleteWorkflowRule(row)}></DeleteButton>
                                            </td>
                                        </MapleTable.TR>
                                    )
                                }
                                {
                                    hasMore &&
                                    <tr>
                                        <td colSpan={10} className="text-center">
                                            <div className="spinner-border text-secondary"/>
                                        </td>
                                    </tr>
                                }
                                </tbody>
                            </MapleTable.Content>
                        </MapleTable>
                    }
                    </Section>
                </InfiniteScroll>
            </div>
        </ContentContainer>
        <CreateOrUpdateWorkflowRuleModal
            show={showWorkflowRuleModal}
            onClose={onWorkflowRuleModalClose}
            currentRule={currentRule}
            ruleAreas={ruleAreas}
            ruleMap={ruleMap}
            channels={channels}
            messageMap={messageMap}
            selectedChannel={getSlackChannelNameFromRule(currentRule)}
            integrationID={integration.id}
        />
        </>
)
}

export default WorkflowRulesComponent;