import '../App.scss';
import '../js/forms.js';
import React, {useEffect, useMemo, useState} from 'react';
import { useTranslation } from 'react-i18next';
import moment from "moment/moment";
import $ from "jquery";
import {Button} from "react-bootstrap";
const _ = require("lodash");

function DateRangePicker(props) {
    const { t } = useTranslation('common');
    const [clearable, setClearable] = useState(true);
    const [dateRangeLabel, setDateRangeLabel] = useState("All");
    const [randomId, setRandomId] = useState(null);

    const customRanges = useMemo(() => {
        return {
            'Today': [moment().startOf('day'), moment().endOf('day')],
            'Yesterday': [moment().startOf('day').subtract(1, 'days'), moment().subtract(1, 'days').endOf('day')],
            'Last 7 Days': [moment().subtract(6, 'days').startOf('day'), moment().endOf('day')],
            'Last 30 Days': [moment().subtract(29, 'days').startOf('day'), moment().endOf('day')],
            'This Month': [moment().startOf('month'), moment().endOf('day')],
            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            'This Year': [moment().startOf('year'), moment().endOf('day')],
            'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]
        }
    }, []);

    useEffect(() => {
        setRandomId(Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5))
    }, []);

    useEffect(() => {
        if (props.defaultValue) {
            const start = props.defaultValue.start && moment(props.defaultValue.start);
            const end = props.defaultValue.end && moment(props.defaultValue.end);
            if (start && end) {
                let newLabel = 'All'
                _.each(customRanges, (range, label) => {
                    if (range[0].isSame(start, 'day') && range[1].isSame(end, 'day')) {
                        newLabel = label;
                    }
                })
                if (newLabel === 'All') {
                    newLabel = start.format('MMM D') + " - " + end.format('MMM D')
                }
                setDateRangeLabel(newLabel);
            }
        }
    }, [props.defaultValue])

    useEffect(() => {
        if (_.isNil(randomId)) {
            return;
        }
        $( function() {
            const name = '#' + randomId + "-date-range-calendar"
            const startDate = (props.defaultValue && props.defaultValue.start) ? moment(props.defaultValue.start): undefined;
            const endDate = (props.defaultValue && props.defaultValue.end) ? moment(props.defaultValue.end): undefined;
            $(name).daterangepicker({
                opens: props.iconOnLeft ? "right": "left",
                autoApply: true,
                startDate: startDate,
                endDate: endDate,
                maxDate: moment().format("MM/DD/YYYY"),
                timePicker: true,
                ranges: customRanges,
            }, function(start, end, label) {
                let s = start;
                let e = end;
                if (label === 'Custom Range') {
                    setDateRangeLabel(start.format('MMM D') + " - " + end.format('MMM D'));
                } else {
                    setDateRangeLabel(label);
                    s = customRanges[label][0]
                    e = customRanges[label][1]
                }
                if (props.onDatesChange) {
                    props.onDatesChange(s, e);
                }
            });
        });
    }, [randomId])

    return (
        <div className="flex flex-row gap-2 px-1 w-full">
            <div id={randomId + "-date-range-calendar"} className="flex flex-row gap-2 flex-shrink-0 items-center grow">
                {
                    props.iconOnLeft && <div className="calendar-icon"/>
                }
                <div className="grow">
                    <span className="body1 gray4">{ dateRangeLabel }</span>
                </div>
                {
                    !props.iconOnLeft && <div className="calendar-icon"/>
                }
            </div>
            {
                props.iconOnLeft && clearable &&
                <Button
                    variant="text" className="thin" size="sm"
                    onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        setDateRangeLabel("All")
                        if (props.onDatesChange) {
                            props.onDatesChange(null, null);
                        }
                    }}>
                    <i className="fa fa-x gray3-color"/>
                </Button>
            }
        </div>
    );
}

export default DateRangePicker;
