import '../App.scss';
import variables from "../App.scss";
import '../css/invoice.scss';
import React, { useEffect, useState, useContext } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import {
    BaseContext,
    currencyFormatFromPrice,
    getCustomerNameOrEmail,
    getInvoicePaymentTermDescription,
    getInvoicePaymentTermFromDueDateFromCreation,
    getNameForProviderType,
    renderAddress,
    UserContext
} from '../helpers/common';
import { useTranslation } from 'react-i18next';
import Loader from './Loader';
import PricingBreakdown from './PricingBreakdown';
import { Table, Row, Col } from 'react-bootstrap';
import moment from 'moment';
import classnames from 'classnames';
import {serverFetch} from "../helpers/server";
import Link from "./Link";
import PricingBreakdownSummary from "./PricingBreakdownSummary";
const _ = require('lodash');

function InvoiceDetailsComponent(props) {
    const { isLoggedIn } = useContext(UserContext);
    const { getCompanySpecificUrl, getApiUrl } = useContext(BaseContext)
    const [ searchParams ] = useSearchParams();
    const navigate = useNavigate();
    let redirectStatus = searchParams.get('redirect_status');

    const { t } = useTranslation('common');
    const [invoiceDetails, setInvoiceDetails] = useState({});
    const [settings, setSettings] = useState({});

    const [isPaid, setIsPaid] = useState(false);

    useEffect(() => {
        setInvoiceDetails(props.invoice);
    }, [props.invoice])

    useEffect(() => {
        setSettings(props.settings);
    }, [props.settings])

    useEffect(() => {
        if (_.isNil(redirectStatus)) {
            return;
        }
        setTimeout(() => {
            let urlParser = new URL(window.location.href);
            urlParser.searchParams.delete('payment_intent');
            urlParser.searchParams.delete('payment_intent_client_secret');
            urlParser.searchParams.delete('redirect_status');
            navigate(urlParser.pathname + urlParser.search, { replace: true });
        }, 1000);
    }, [redirectStatus])

    useEffect(() => {
        const _isPaid = !_.isEmpty(invoiceDetails.due) && invoiceDetails.due.value_in_cents === 0;

        setIsPaid(_isPaid);
    }, [settings, invoiceDetails, props.readOnly])

    const renderKeyValue = (key, value, j) => {
        return (
            <div className="d-flex flex-row" key={j}>
                <div className="body2 half">{ key }</div>
                <div className="body1 half">{ value }</div>
            </div>
        )
    }

    const goToHostedLink = (event) => {
        event.preventDefault();
        event.stopPropagation();
        serverFetch(getApiUrl(`/invoices/${invoiceDetails.uuid}/provider_link`), { skipCache: true }).then((res) => {
            if (res) {
                window.open(res.link, '_blank');
            }
        })
    }

    const renderRolledUpInvoices = (invoices) => {
        return <div className="flex flex-col gap-6">
            {
                _.map(invoices, (details, p) =>
                    <div key={p}>
                        <div className="flex flex-row gap-1 p-1 text-sm font-normal text-gray-500">
                            <span>
                                <Link href={getCompanySpecificUrl(`/customer/${details.customer_id}`)}>
                                    { getCustomerNameOrEmail(details.customer) }
                                </Link>
                            </span>
                            <span>-</span>
                            <span>
                                Invoiced on { moment(details.invoice_date).format("D MMMM, YYYY") }
                            </span>
                            <span>
                                (<Link href={getCompanySpecificUrl(`/invoice/${details.uuid}`)}>
                                    View
                                </Link>)
                            </span>
                        </div>
                        <PricingBreakdown
                            isAdmin={props.isAdmin}
                            taxDetails={details.tax_details}
                            discountDetails={details.discount_details}
                            creditDetails={details.credit_details}
                            subscriptionDetails={details.subscription_details}
                            oneTimeDetails={details.one_time_details}
                            currency={details.currency}
                            subTotal={details.sub_total}
                            refunds={details.refunds}
                            total={details.total}
                            paid={details.paid}
                            showTaxAsEstimate={props.showTaxAsEstimate}
                            onChangeIncludeTaxes={props.onChangeIncludeTaxes}
                            prePaymentCreditNotes={details.pre_payment_credit_notes}
                            postPaymentCreditNotes={details.post_payment_credit_notes}
                            expanded={props.expanded}
                        />
                    </div>
                )
            }
            <PricingBreakdownSummary
                isAdmin={props.isAdmin}
                taxDetails={invoiceDetails.tax_details}
                discountDetails={invoiceDetails.discount_details}
                creditDetails={invoiceDetails.credit_details}
                subscriptionDetails={invoiceDetails.subscription_details}
                oneTimeDetails={invoiceDetails.one_time_details}
                subTotal={invoiceDetails.sub_total}
                refunds={invoiceDetails.refunds}
                total={invoiceDetails.total}
                due={invoiceDetails.due}
                paymentProcessing={invoiceDetails.payment_processing_amount}
                paid={invoiceDetails.paid}
                showTaxDetailsModal={props.showTaxDetailsModal}
                showTaxAsEstimate={props.showTaxAsEstimate}
                onChangeIncludeTaxes={props.onChangeIncludeTaxes}
            />
        </div>
    }

    const renderPricingBreakdown = (details) => {
        return <PricingBreakdown
            isAdmin={props.isAdmin}
            taxDetails={details.tax_details}
            discountDetails={details.discount_details}
            creditDetails={details.credit_details}
            subscriptionDetails={details.subscription_details}
            oneTimeDetails={details.one_time_details}
            currency={details.currency}
            subTotal={details.sub_total}
            refunds={details.refunds}
            total={details.total}
            due={details.due}
            paymentProcessing={invoiceDetails.payment_processing_amount}
            paid={details.paid}
            showTaxDetailsModal={props.showTaxDetailsModal}
            showTaxAsEstimate={props.showTaxAsEstimate}
            onChangeIncludeTaxes={props.onChangeIncludeTaxes}
            prePaymentCreditNotes={details.pre_payment_credit_notes}
            postPaymentCreditNotes={details.post_payment_credit_notes}
            expanded={props.expanded}
        />
    }

    const renderImportedInvoiceDetails = () => {
        return (
            <div className="invoice-container">
                <Row className="invoice-header">
                    <Col sm="8" className="d-flex flex-column">
                        { renderKeyValue("Invoice Number", invoiceDetails.number) }
                        {
                            isLoggedIn ?
                                renderKeyValue("Bill To", <Link
                                    href={getCompanySpecificUrl(`/customer/${invoiceDetails.customer.id}`)}>{getCustomerNameOrEmail(invoiceDetails.customer)}</Link>)
                                :
                                renderKeyValue("Bill To", <span>{getCustomerNameOrEmail(invoiceDetails.customer)}</span>)
                        }
                        {
                            !isPaid && invoiceDetails.due_date &&
                            renderKeyValue("Due Date",
                                <div className="d-flex flex-column">
                                    <span>{ moment(invoiceDetails.due_date).format("D MMMM, YYYY") }</span>
                                    <span className={classnames("caption", moment(invoiceDetails.due_date).isAfter(moment()) ? "": "error")}>
                                                                    due { moment(invoiceDetails.due_date).from(moment()) }
                                                                </span>
                                </div>
                            )
                        }
                        {
                            isPaid && invoiceDetails.paid_date &&
                            renderKeyValue("Paid Date",
                                <div className="d-flex flex-column">
                                    <span>{ moment(invoiceDetails.paid_date).format("D MMMM, YYYY") }</span>
                                </div>
                            )
                        }
                        { renderKeyValue("Total", currencyFormatFromPrice(invoiceDetails.total)) }
                        { !isPaid && renderKeyValue("Amount Due", currencyFormatFromPrice(invoiceDetails.due)) }
                    </Col>
                    <Col sm="4" className="text-end"/>
                </Row>
                <p>This invoice was imported from { getNameForProviderType(invoiceDetails.imported_from) }. For full details on the invoice,
                    please visit <a href={`provider_link`} onClick={(event) => goToHostedLink(event)} target="_blank">their invoice page</a></p>
            </div>
        )
    }

    const renderInvoiceDetails = () => {
        const dueDate = moment(invoiceDetails.due_date);
        const invoiceDate = moment(invoiceDetails.invoice_date).seconds(0).millisecond(0);
        return (
            <Loader loading={_.isEmpty(invoiceDetails)}>
                {
                    () =>
                        <div className={classnames("invoice-container", props.printable ? "printable" : "")}>
                            <Row className="invoice-header">
                                <Col sm="6" className="d-flex flex-column">
                                    <div className="flex flex-row items-center">
                                        <h2 className="grow">{settings.invTitle || "Invoice"}</h2>
                                        {
                                            settings.invoice_logo_url ?
                                                <img alt="Company Logo" className="invoice-logo block sm:hidden" id="invoice-logo"
                                                     src={settings.invoice_logo_url}/>
                                                : <div id="invoice-logo"/>
                                        }
                                    </div>
                                    <br/>
                                    {!props.readOnly && renderKeyValue("Invoice Number", invoiceDetails.number)}
                                    {renderKeyValue("Issue Date", moment(invoiceDetails.invoice_date).format("D MMMM, YYYY"))}
                                    {renderKeyValue("Payment Term", getInvoicePaymentTermDescription(dueDate.utc().diff(invoiceDate.utc(), 'days')))}
                                    {
                                        renderKeyValue("Due Date",
                                            <div className="flex flex-col">
                                                <span>{ moment(invoiceDetails.due_date).format("D MMMM, YYYY") }</span>
                                                {
                                                    moment(invoiceDetails.due_date).isBefore(moment()) && invoiceDetails.status === "PENDING" && !props.printable &&
                                                    <>
                                                        <span className="danger-color caption mb-1">
                                                            {
                                                                moment().diff(moment(invoiceDetails.due_date), "days") > 0 ?
                                                                    <span>Overdue by { moment().diff(moment(invoiceDetails.due_date), "days") } days</span>
                                                                    : <span>Overdue</span>
                                                            }
                                                        </span>
                                                    </>
                                                }
                                            </div>
                                        )
                                    }
                                    {
                                        !isPaid && !invoiceDetails.due_date && invoiceDetails.due_date &&
                                        renderKeyValue("Due Date",
                                            <div className="d-flex flex-column">
                                                <span>{moment(invoiceDetails.due_date).format("D MMMM, YYYY")}</span>
                                                <span
                                                    className={classnames("caption", moment(invoiceDetails.due_date).isAfter(moment()) ? "" : "error")}>
                                                    due {moment(invoiceDetails.due_date).from(moment())}
                                                </span>
                                            </div>
                                        )
                                    }
                                    {
                                        isPaid && invoiceDetails.paid_date &&
                                        renderKeyValue("Paid Date",
                                            <div className="d-flex flex-column">
                                                <span>{moment(invoiceDetails.paid_date).format("D MMMM, YYYY")}</span>
                                            </div>
                                        )
                                    }
                                    {
                                        invoiceDetails.subscription != null &&
                                        _.map(invoiceDetails.subscription.invoice_additional_display_fields, (f, i) => {
                                            return renderKeyValue(f.name, f.value, i);
                                        })
                                    }
                                    {
                                        _.map(settings.invoice_additional_display_fields, (f, i) => {
                                            return renderKeyValue(f.name, f.value, i);
                                        })
                                    }
                                    {
                                        invoiceDetails.additional_display_fields != null &&
                                        _.map(invoiceDetails.additional_display_fields, (f, i) => {
                                            return renderKeyValue(f.name, f.value, i);
                                        })
                                    }
                                    {
                                        (invoiceDetails.rollup_invoices && invoiceDetails.rollup_invoices.length > 0) && invoiceDetails.billed_customer.child_rollup_billing &&
                                            <>
                                            {
                                                _.map(invoiceDetails.billed_customer.child_rollup_billing_config.invoice_additional_display_fields, (f, i) => {
                                                    return renderKeyValue(f.name, f.value, i);
                                                })
                                            }
                                            </>
                                    }
                                </Col>
                                <Col sm="6" className="d-flex align-items-center justify-content-end">
                                    {
                                        settings.invoice_logo_url ?
                                            <img alt="Company Logo" className="invoice-logo hidden sm:block" id="invoice-logo"
                                                 src={settings.invoice_logo_url}/>
                                        : <div id="invoice-logo"/>
                                    }
                                </Col>
                            </Row>
                            <Row className="invoice-header">
                                <Col xs="6" sm="4" className="d-flex flex-column">
                                    <div className="body2">From:</div>
                                    <div className="body1">{invoiceDetails.company.name}</div>
                                    <div className="body1">{renderAddress(invoiceDetails.company.address)}</div>
                                </Col>
                                <Col xs="6" sm="4" className="d-flex flex-column">
                                    <div className="body2">Bill To:</div>
                                    <div className="body1">
                                        {
                                            isLoggedIn ?
                                                <Link
                                                    href={getCompanySpecificUrl(`/customer/${invoiceDetails.billed_customer.id}`)}>{getCustomerNameOrEmail(invoiceDetails.billed_customer)}</Link>
                                                :
                                                <span>{getCustomerNameOrEmail(invoiceDetails.billed_customer)}</span>
                                        }
                                    </div>
                                    <div className="body1">{renderAddress(invoiceDetails.customer.address)}</div>
                                </Col>
                                <Col xs="6" sm="4" className="d-flex flex-column">
                                    <div className="body2">Ship To:</div>
                                    <div className="body1">
                                        {
                                            isLoggedIn ?
                                                <Link
                                                    href={getCompanySpecificUrl(`/customer/${invoiceDetails.customer.id}`)}>{getCustomerNameOrEmail(invoiceDetails.customer)}</Link>
                                                :
                                                <span>{getCustomerNameOrEmail(invoiceDetails.customer)}</span>
                                        }
                                    </div>
                                    <div className="body1">{renderAddress(invoiceDetails.customer.address)}</div>
                                </Col>
                            </Row>
                            <br/>
                            {
                                (invoiceDetails.rollup_invoices && invoiceDetails.rollup_invoices.length > 0) ?
                                    renderRolledUpInvoices(invoiceDetails.rollup_invoices)
                                : renderPricingBreakdown(invoiceDetails)
                            }
                            <div className="invoice-subdetails">
                                <div dangerouslySetInnerHTML={{__html: invoiceDetails.notes}}/>
                            </div>
                            {
                                !!invoiceDetails.payment_instructions &&
                                    <div className="mt-2">
                                        <span className="mb-1 font-semibold">Payment Instructions</span>
                                        <div dangerouslySetInnerHTML={{__html: invoiceDetails.payment_instructions}}/>
                                    </div>
                            }
                            {
                                props.printable &&
                                    <div className="print-no-break">
                                        <br/>
                                        <img src="/static/images/cut-mark.jpeg" alt="cut" style={{ width: "100%" }}/>
                                        <br/>
                                        <div className="d-flex flex-row gap-3">
                                            <div className="flex-grow-1">
                                                <h2>PAYMENT ADVICE</h2>
                                                <div className="d-flex flex-row gap-3" style={{ marginLeft: "30px" }}>
                                                    <div>
                                                        <span className="body1">To:</span>
                                                    </div>
                                                    <div>
                                                        <span className="body1">{invoiceDetails.company.name}</span>
                                                        <div className="body1">{renderAddress(invoiceDetails.company.address)}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-grow-1">
                                                <Table borderless>
                                                    <tbody>
                                                        <tr className="thin-horizontal">
                                                            <td className="py-1"><span className="body2 no-stretch">Customer</span></td>
                                                            <td className="py-1"><span className="body1">{ getCustomerNameOrEmail(invoiceDetails.customer) }</span></td>
                                                        </tr>
                                                        <tr className="thin-horizontal" style={{ borderBottom: "1px solid " + variables.gray1Color }}>
                                                            <td className="py-1"><span className="body2 no-stretch">Invoice Number</span></td>
                                                            <td className="py-1"><span className="body1">{ invoiceDetails.number }</span></td>
                                                        </tr>
                                                        <tr className="thin-horizontal">
                                                            <td className="py-1"><span className="body2 no-stretch">Amount Due</span></td>
                                                            <td className="py-1"><span className="body2">{ currencyFormatFromPrice(invoiceDetails.due) }</span></td>
                                                        </tr>
                                                        <tr className="thin-horizontal" style={{ borderBottom: "1px solid " + variables.gray1Color }}>
                                                            <td className="py-1"><span className="body2 no-stretch">Due Date</span></td>
                                                            <td className="py-1"><span className="body1">{ moment(invoiceDetails.due_date).format("D MMMM, YYYY") }</span></td>
                                                        </tr>
                                                        <tr className="thin-horizontal">
                                                            <td className="py-1"><span className="body2 no-stretch">Amount Enclosed</span></td>
                                                            <td className="py-1"></td>
                                                        </tr>
                                                        <tr className="thin-horizontal">
                                                            <td className="py-1"><span className="body2 no-stretch"></span></td>
                                                            <td className="py-1"><div style={{ borderTop: "1px solid " + variables.gray3Color, width: "100%", paddingTop: "3px" }} className="caption">Enter the amount you are paying above</div></td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </div>
                                    </div>
                            }
                        </div>
                }
            </Loader>
        );
    }

    const isImported = !_.isNil(invoiceDetails.imported_from);
    if (isImported) {
        return renderImportedInvoiceDetails();
    } else {
        return renderInvoiceDetails();
    }
}

export default InvoiceDetailsComponent;
