import '../../../App.scss';
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {serverPatch, serverDelete, serverPost, notifyEvent, serverFetch} from '../../../helpers/server';
import {BaseContext, IntegrationContext} from '../../../helpers/common';
import {
    getFeatureDescriptions,
    getIntegrationInterfaceForKey,
    isPaymentProviderEnabled,
} from '../../../helpers/integrations';
import ContentContainer from '../../../components/ContentContainer';
import {Container, Button, Row, Col, Table} from 'react-bootstrap';
import DeleteButton from '../../../components/DeleteButton';
import ContentBox from '../../../components/ContentBox';
import {Switch} from "@headlessui/react";
import classnames from "classnames";
import Notification from "../../../components/Notification";
import TaxCalcProductIDMappingComponent from "../../../components/TaxCalcProductIDMappingComponent";
import AccountingProductIDMappingComponent from "../../../components/AccountingProductIDMappingComponent";
import AccountingTaxRatesMappingComponent from "../../../components/AccountingTaxRatesMappingComponent";
import IntegrationSelectAccountingAccount from "../../../components/IntegrationSelectAccountingAccount";
import IntegrationSelectAccountingBankAccount from "../../../components/IntegrationSelectAccountingBankAccount";
import CopyableComponent from "../../../components/CopyableComponent";
import Section from "../../../components/Section";
import TaxCalcCustomTaxRules from "../../../components/TaxCalcCustomTaxRules";
import TaxCalcCustomTaxIdentifiers from "../../../components/TaxCalcCustomTaxIdentifiers";
import WorkflowRulesComponent from "../../../components/WorkflowRulesComponent";
const _ = require('lodash');

function Settings() {
    const { getApiUrl, getCompanySpecificUrl, setPageTitle } = useContext(BaseContext);
    const { integration } = useContext(IntegrationContext);
    const [isEditing, setIsEditing] = useState(false);
    const navigate = useNavigate();
    const integrationInterface = getIntegrationInterfaceForKey(integration.key);

    useEffect(() => {
        setPageTitle(`Integrations - Settings`);
    }, []);

    const removeIntegration = (data) => {
        serverDelete(getApiUrl(`/integrations/${integration.id}`)).then((res) => {
            navigate(getCompanySpecificUrl("/integrations"));
        })
    }

    const reauthorizeIntegration = (data) => {
        serverFetch(getApiUrl(`/integrations/${integration.id}/oauth_url`)).then(res => {
            if (res) {
                window.location.href = res;
            }
        })
    }

    const updateApiKey = (data) => {
        const authData = {};
        if (data.api_key !== integration.api_key_data.api_key) {
            authData.api_key = data.api_key
        }
        if (data.api_id !== integration.api_key_data.api_id) {
            authData.api_id = data.api_id
        }
        if (data.client_secret !== integration.api_key_data.client_secret) {
            authData.client_secret = data.client_secret
        }
        if (_.isEmpty(authData)) {
            return;
        }
        const updateData = {
            authentication: authData
        }
        serverPatch(getApiUrl(`/integrations/${integration.id}`), updateData).then((res) => {
            if (res) {
                setIsEditing(false);
                Notification.Success("Successfully updated");
            }
        })
    }

    const onFeatureEnabledChange = (name, value) => {
        let newEnabledFeatures = integration.enabled_features;
        if (value) {
            newEnabledFeatures = _.union(integration.enabled_features, [name]);
        } else {
            newEnabledFeatures = _.filter(integration.enabled_features, f => f != name);
        }
        serverPatch(getApiUrl(`/integrations/${integration.id}`), { enabled_features: newEnabledFeatures }).then(res => {
            notifyEvent('integration_settings');
        })
    }

    const featureDescriptions = getFeatureDescriptions(integration);
    return (
        <ContentContainer>
            <Section
                title={`Settings`}
                subtitle={`Configure settings for ${integration.name} and Maple.`}
            >
            <ContentBox className="mt-2">
                <ContentBox.Body>
                    <div className="text-base font-semibold mb-4">Features</div>
                    <div className="flex flex-col gap-3">
                    {
                        _.map(featureDescriptions, (feature, g) =>
                            <div key={g} className="flex flex-row items-center gap-2">
                                <div className="flex flex-col gap-1 grow">
                                    <span className="text-gray-900 text-sm font-semibold">{ feature.title }</span>
                                    <span className="text-gray-500 text-sm">{ feature.description }</span>
                                </div>
                                <div>
                                    <Switch.Group as="div" className="flex items-center">
                                        <Switch
                                            checked={_.includes(integration.enabled_features, feature.key)}
                                            onChange={() => onFeatureEnabledChange(feature.key, !_.includes(integration.enabled_features, feature.key))}
                                            className={classnames(
                                                _.includes(integration.enabled_features, feature.key) ? 'bg-indigo-600' : 'bg-gray-200',
                                                'relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-0'
                                            )}
                                        >
                                            <span
                                                aria-hidden="true"
                                                className={classnames(
                                                    _.includes(integration.enabled_features, feature.key) ? 'translate-x-5' : 'translate-x-0',
                                                    'pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                                )}
                                            />
                                        </Switch>
                                        <Switch.Label as="span" className="ml-1 text-sm">
                                            <span className="font-normal text-gray-500"></span>
                                        </Switch.Label>
                                    </Switch.Group>
                                </div>
                            </div>
                        )
                    }
                    </div>
                </ContentBox.Body>
            </ContentBox>
                {
                    _.includes(integration.categories, 'NOTIFICATIONS') && _.includes(integration.enabled_features, 'NOTIFICATIONS') &&
                    <ContentBox>
                        <ContentBox.Body>
                            <div className="flex flex-row gap-3">
                                <div className="flex-grow-1">
                                    <div className="">
                                        <WorkflowRulesComponent/>
                                    </div>
                                </div>
                            </div>
                        </ContentBox.Body>
                    </ContentBox>
                }
                {
                    _.includes(integration.categories, 'TAX_CALC') && _.includes(integration.enabled_features, "TAX_CALC") && integration.key !== "manual_tax" &&
                    <ContentBox>
                        <ContentBox.Body>
                            <div className="flex flex-row gap-3">
                                <div className="flex-grow-1">
                                <h5>Tax Products Mapping</h5>

                                    <div className="max-w-2xl mt-4">
                                        <TaxCalcProductIDMappingComponent/>
                                    </div>
                                </div>
                            </div>
                        </ContentBox.Body>
                    </ContentBox>
            }
            {
                _.includes(integration.categories, 'TAX_CALC') && _.includes(integration.enabled_features, "TAX_CALC") && integration.key === "manual_tax" &&
                <ContentBox>
                    <ContentBox.Body>
                        <div className="flex flex-row gap-3">
                            <div className="flex-grow-1">
                                <h5>Custom Tax Rules</h5>

                                <div className="max-w-2xl mt-4">
                                    <TaxCalcCustomTaxRules/>
                                </div>
                            </div>
                        </div>
                    </ContentBox.Body>
                </ContentBox>
            }
            {
                _.includes(integration.categories, 'TAX_CALC') && _.includes(integration.enabled_features, "TAX_CALC") && integration.key === "manual_tax" &&
                <ContentBox>
                    <ContentBox.Body>
                        <div className="flex flex-row gap-3">
                            <div className="flex-grow-1">
                                <h5>Tax Registration Numbers</h5>

                                <div className="max-w-2xl mt-4">
                                    <TaxCalcCustomTaxIdentifiers/>
                                </div>
                            </div>
                        </div>
                    </ContentBox.Body>
                </ContentBox>
            }
            {
                _.includes(integration.categories, 'ACCOUNTING') && _.includes(integration.enabled_features, "ACCOUNTING") &&
                <ContentBox>
                    <ContentBox.Body>
                        <div className="flex flex-row gap-3">
                            <div className="flex-grow-1">
                                <h5>Accounting Products Mapping</h5>

                                <div className="max-w-2xl mt-4">
                                    <AccountingProductIDMappingComponent/>
                                </div>
                            </div>
                        </div>
                    </ContentBox.Body>
                </ContentBox>
            }
            {
                _.includes(integration.categories, 'ACCOUNTING') && _.includes(integration.enabled_features, "ACCOUNTING") &&
                <ContentBox>
                    <ContentBox.Body>
                        <div className="flex flex-row gap-3">
                            <div className="flex-grow-1">
                                <h5>Tax Rates Mapping</h5>

                                <div className="max-w-2xl mt-4">
                                    <AccountingTaxRatesMappingComponent/>
                                </div>
                            </div>
                        </div>
                    </ContentBox.Body>
                </ContentBox>
            }
            {
                _.includes(integration.categories, 'ACCOUNTING') &&
                    <IntegrationSelectAccountingAccount />
            }
            {
                _.includes(integration.categories, 'ACCOUNTING') &&
                    <IntegrationSelectAccountingBankAccount />
            }
            {
                integration.auth_type === "api" &&
                <ContentBox>
                    <ContentBox.Body>
                        <div className="d-flex flex-row gap-3">
                            <div className="flex-grow-1">
                                <h5>API Authorization</h5>
                            </div>
                            <div className="flex-shrink-0 d-flex align-items-center">
                                {
                                    isEditing ?
                                        <Button variant="text-danger" onClick={() => setIsEditing(false)}>Cancel</Button>
                                        : <Button variant="text-primary" onClick={() => setIsEditing(true)}><i className="fa fa-edit" /> Edit</Button>
                                }
                            </div>
                        </div>
                        {
                            isEditing ?
                                <Container>
                                    <Row>
                                        <Col md="8">
                                            { integrationInterface.getApiInstructions(updateApiKey, integration.api_key_data) }
                                        </Col>
                                    </Row>
                                </Container>
                                : <div className="flex flex-row gap-2">
                                    <span className="text-sm font-semibold text-gray-700">API Key:</span>
                                    <span className="text-sm font-normal text-gray-500">{ integration.api_key_data && integration.api_key_data.api_key }</span>
                                </div>
                        }
                    </ContentBox.Body>
                </ContentBox>
            }
            {
                !_.isEmpty(integration.connection_data) &&
                <ContentBox>
                    <ContentBox.Body>
                        <div className="flex flex-col gap-3">
                            <h5 className="text-md font-semibold">{ integration.name } Organization</h5>
                            <div className="grow flex flex-row gap-2 items-center">
                                {
                                    integration.connection_data && integration.connection_data.org_id &&
                                    <>
                                        <div className="body1">Organization: </div>
                                        <CopyableComponent
                                            value={ `${integration.connection_data.org_name} (${integration.connection_data.org_id})` }
                                        />
                                    </>
                                }
                            </div>
                        </div>
                    </ContentBox.Body>
                </ContentBox>
            }
            {
                isPaymentProviderEnabled(integration) && integration.api_key_data &&
                    <ContentBox>
                        <ContentBox.Body>
                            <div className="flex flex-col gap-3">
                                <h5 className="text-md font-semibold">{ integration.name } Account</h5>
                                <div className="grow flex flex-row gap-2 items-center">
                                    {
                                        integration.api_key_data && integration.api_key_data.api_id &&
                                        <>
                                            <div className="body1">Account: </div>
                                            <CopyableComponent value={ integration.api_key_data.api_id } />
                                        </>
                                    }
                                </div>
                            </div>
                        </ContentBox.Body>
                    </ContentBox>
            }
            {
                integration.auth_type === "oauth2" &&
                <ContentBox>
                    <ContentBox.Body>
                        <div className="d-flex flex-row gap-3">
                            <div className="flex-grow-1">
                                <h5>Re-authorize Oauth</h5>
                                <br/>
                                <div className="body1">Due to some unforeseen circumstances, sometimes the permissions for { integration.name } gets invalid or expires. In those cases, you can simply re-authorize Maple with { integration.name } to fix the issue</div>
                            </div>
                            <div className="flex-shrink-0 d-flex align-items-center">
                                <Button onClick={reauthorizeIntegration}>Re authorize</Button>
                            </div>
                        </div>
                    </ContentBox.Body>
                </ContentBox>
            }
            <ContentBox>
                <ContentBox.Body>
                    <div className="d-flex flex-row gap-3">
                        <div className="flex-grow-1">
                            <h5>Remove Integration</h5>
                            <br/>
                            <div className="body1">Please be careful when removing an integration as this action can not be undone. You will lose all of the settings, field mappings and syncs will stop going forward.</div>
                        </div>
                        <div className="flex-shrink-0 d-flex align-items-center">
                            <DeleteButton onDelete={removeIntegration}>Remove Integration</DeleteButton>
                        </div>
                    </div>
                </ContentBox.Body>
            </ContentBox>
            </Section>
        </ContentContainer>
    );
}

export default Settings;
