import React, {useEffect, useState} from "react";
import BaseAddOrEditItemModal from "./BaseAddOrEditItemModal";
import {Row} from "react-bootstrap";
import BaseForm from "../BaseForm";
import {formatEventString, getApprovalRulePropertyKeys, getApprovalRulePropertyValues} from "../../helpers/common";
import CopyableContainer from "../CopyableContainer";
const _ = require('lodash');

function CreateOrUpdateWorkflowRuleModal(props) {
    const [itemToEdit, setItemToEdit] = useState(null);
    const [areas, setAreas] = useState([]);
    const [triggers, setTriggers] = useState([]);
    const [rulePropertyValues, setRulePropertyValues] = useState([]);
    const [rulePropertyKeys, setRulePropertyKeys] = useState([]);
    const [slackChannels, setSlackChannels] = useState([]);
    const [currentMessage, setCurrentMessage] = useState("");

    useEffect(() => {
        if (!_.isNil(props.currentRule)) {
            const fields = {
                id: props.currentRule.id,
                name: props.currentRule.name,
                area: _.split(_.split(props.currentRule.trigger_event, ".")[0], "_")[0],
                trigger: props.currentRule.trigger_event,
                rule: props.currentRule.rule,
                channel: props.selectedChannel
            }
            setItemToEdit(fields);
        } else {
            setItemToEdit(null);
        }

    }, [props.currentRule, props.selectedChannel])

    useEffect(() => {
        const options = _.map(props.ruleAreas, (a) => {
            return {
                value: a,
                label: _.startCase(a)
            }
        })

        setAreas(options);
    }, [props.ruleAreas]);

    useEffect(() => {
        const options = _.map(props.channels, (c) => {
            return {
                value: c.name,
                label: c.name,
            }
        })
        setSlackChannels(options)
    }, [props.channels])

    const onFieldChange = (name, value) => {
        if (name === "area") {
            setCurrentMessage("")
            const triggers = props.ruleMap[value]["events"]
            const options = _.map(triggers, (t) => {
                return {
                    value: t,
                    label: formatEventString(t)
                }
            })
            setTriggers(options)

            const rules = props.ruleMap[value]["rules_options"]
            const ruleValueOptions = _.map(rules, (r) => {
                return {
                    value: r.key,
                    label: r.label
                }
            })
            const ruleKeyOptions = _.map(rules, (r) => {
                return {
                    Key: r.key,
                    Type: r.type
                }
            })
            setRulePropertyKeys(ruleKeyOptions)
            setRulePropertyValues(ruleValueOptions)
        } else if (name === "trigger") {
            setCurrentMessage(props.messageMap[value])
        }
    }

    const processFields = (fields) => {
        const processedFields = {
            name: fields.name,
            trigger_event: fields.trigger,
            rule: fields.rule,
            workflow_actions: [{
                action_type: "SEND_MESSAGE",
                integration_id: props.integrationID,
                action_params: {
                    slack: {
                        channel: fields.channel,
                        text: currentMessage,
                    }
                }
            }]
        }
        return processedFields;
    }

    return (
        <BaseAddOrEditItemModal
            {...props}
            size={"lg"}
            itemLabel={"Rule"}
            itemBaseUrl={"/workflows"}
            onFieldChange={onFieldChange}
            itemToEdit={itemToEdit}
            processAddFields={processFields}
            processUpdateFields={processFields}
        >
            <Row>
                <BaseForm.Input colSpan="12" name="name" label="Rule Name" type="text" required/>
                <BaseForm.Input colSpan="12" name="area" label="Domain" type="select" required options={areas} showSearch={false}/>
                <BaseForm.Input colSpan="12" name="trigger" label="Trigger" type="select" required options={triggers} showSearch={false}/>
                <BaseForm.Input
                    colSpan="12" name="rule" label="Rule" type="rule" isColored={true}
                    propertyValues={rulePropertyValues}
                    propertyFields={rulePropertyKeys}
                    required
                />
                <BaseForm.Input colSpan="12" name="channel" label="Slack Channel" type="select" required options={slackChannels} showSearch={true}/>
                {
                    currentMessage &&
                    <>
                    <div className={"text-sm font-semibold text-gray-700"}>Message Template</div>
                    <br/>
                    <CopyableContainer copyableValue={currentMessage} />
                    </>
                }
            </Row>
        </BaseAddOrEditItemModal>
    )

}

export default CreateOrUpdateWorkflowRuleModal;